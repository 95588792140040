import React, { useState, useEffect, Component } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import "../css/programs-page.css";

import cardImg from "../images/programs/degree.svg";
import cardImg1 from "../images/programs/start online.svg";
import cardImg2 from "../images/programs/get your visa.svg";
import cardImg3 from "../images/programs/completed overseas.svg";

import bussinessImag from "../images/programs/Business.png";
import commerseImag from "../images/programs/Commerce.png";
import computerImage from "../images/programs/ComputerScience.png";
import marketingImage from "../images/programs/Marketing.png";
import sportsImage from "../images/programs/Sports.png";
import filmStudiesImage from "../images/programs/filmstudies.png";
import EngineeringImage from "../images/programs/engineering.png";
import scienceImage from "../images/programs/science.png";

import UniLogoSlider from "../components/UniLogoSlider";

import ArrowRightSolidIcon from "../images/icons/arrow-right-solid.svg";
import ArrowLeftSolidIcon from "../images/icons/arrow-left-solid.svg";
import ArrowRightBlueIcon from "../images/icons/arrow-right-solid-blue.svg";
import ReactHtmlParser from "react-html-parser";
import PopularDegreeOutcome from "../components/popular-degree-outcome-new";

import PopupVideo from "../components/popupVideo";

import "../css/swiper-bundle.min.css";

import Swiper, {
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Thumbs,
  Mousewheel,
  Parallax,
} from "swiper";
import "../css/sharan-style.css";
import "../css/animate.min.css";
import "../css/style.css";

import GenericFaqSection from "../components/GenericFaqSection";
import check from "../images/check-icon.svg";
import Layout from "../components/layout";

Swiper.use([
  EffectCoverflow,
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  Thumbs,
  Mousewheel,
  Parallax,
]);

class AmericanDreamUG extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(function () {
      var processBoxHeight =
        document.querySelector(".process-box").offsetHeight;
      var image = document.querySelector(".transcript");
      var imageWidth = image.offsetWidth;
      var imageHeight = image.offsetHeight;
      var aspectRatio = imageWidth / imageHeight;

      image.style.height = processBoxHeight + "px";
      image.style.width = processBoxHeight * aspectRatio + "px";
    }, 1000);

    var divToRemoveFrom = document.querySelector(
      ".program-content-second.desktop"
    );

    var anchorElements = divToRemoveFrom.querySelectorAll("a");

    for (var i = 0; i < anchorElements.length; i++) {
      var anchor = anchorElements[i];
      if (anchor.textContent.includes("Read More")) {
        var paragraph = anchor.parentElement;
        if (paragraph) {
          paragraph.remove();
        }
      }
    }

    const programContent = document.querySelector(
      ".program-content-second.mobile"
    );
    const readMoreLinks = programContent.querySelectorAll("a");
    let readMoreLink;

    for (let i = 0; i < readMoreLinks.length; i++) {
      if (readMoreLinks[i].textContent.includes("Read More")) {
        readMoreLink = readMoreLinks[i];
        break;
      }
    }
    readMoreLink.style.color = "#fcb018";

    const parentElement = readMoreLink.parentElement;
    const elementsToHide = [];
    let currentElement = parentElement;

    while (currentElement.nextElementSibling) {
      if (currentElement.nextElementSibling.tagName === "P") {
        elementsToHide.push(currentElement.nextElementSibling);
      }
      currentElement = currentElement.nextElementSibling;
    }
    if (window.innerWidth <= 768) {
      elementsToHide.forEach((element) => {
        element.style.display = "none";
      });

      const readLessLink = document.createElement("a");
      readLessLink.textContent = "Read Less ↑";
      readLessLink.href = "#";
      readLessLink.style.display = "none";
      readLessLink.style.color = "#fcb018";
      readLessLink.style.paddingBottom = "10px";

      let lastTextElement = parentElement;
      while (
        lastTextElement.nextElementSibling &&
        lastTextElement.nextElementSibling.tagName.match(/^(H|P)/)
      ) {
        lastTextElement = lastTextElement.nextElementSibling;
      }
      lastTextElement.parentNode.insertBefore(
        readLessLink,
        lastTextElement.nextElementSibling
      );

      readMoreLink.addEventListener("click", function (event) {
        event.preventDefault();
        elementsToHide.forEach((element) => {
          element.style.display = "block";
        });
        readMoreLink.style.display = "none";
        readLessLink.style.display = "inline-block";
      });

      readLessLink.addEventListener("click", function (event) {
        event.preventDefault();
        elementsToHide.forEach((element) => {
          element.style.display = "none";
        });
        readMoreLink.style.display = "inline-block";
        readLessLink.style.display = "none";
      });
    } else {
      parentElement.style.display = "none";
      readMoreLink.style.display = "none";
    }

    document.getElementById("speak-to-an-expert").onclick = function () {
      document.getElementById("book-an-appointment").click();
    };
    const spans = document.getElementsByTagName("span");
    for (let span of spans) {
      if (span.textContent.startsWith("*")) {
        span.style.fontSize = "12px";
        span.style.lineHeight = "12px";
      }
    }

    var swiper = new Swiper(".swiper-university", {
      slidesPerView: 1,
      spaceBetween: 50,
      slidesPerGroup: 1,
      loop: false,
      loopFillGroupWithBlank: false,
      watchOverflow: true,

      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination-university",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-next-university",
        prevEl: ".swiper-prev-university",
      },
      breakpoints: {
        1200: { slidesPerGroup: 3, slidesPerView: 3 },
        992: { slidesPerGroup: 3, slidesPerView: 3 },
        768: { slidesPerGroup: 2, slidesPerView: 2 },
      },
    });
  }

  render() {
    const uniArr = this.props.data.allWordpressWpUniversities.nodes;
    const post = this.props.data.allWordpressPage.edges[0].node;
    const metatitle = post.acf.meta_title;
    const metadescription = post.acf.meta_description;
    const focus_keyphrase = post.acf.focus_keyphrase;
    const canonical_url = `https://theworldgrad.com${this.props.path}`;
    const faqList = post.acf.faqs_section?.faqs_item;
    var headerBackground =
      "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
    if (post.featured_media) {
      headerBackground = post.featured_media.source_url;
    }

    const AmericanDreamUndergraduate = (wpUniData) => {
      const [value, setValue] = React.useState(0);
      const [cardData, setCardData] = useState([
        {
          image: cardImg,
          title: "Choose Your Degree",
          text: "Get admission to a top US university in a stream of your choice - business, arts, engineering, technology and more",
        },
        {
          image: cardImg1,
          title: "Start Online",
          text: "Complete the first year of your degree online in just 10 months (6-8 credits per 7 week study period) and receive a transcript from a US university",
        },
        {
          image: cardImg2,
          title: "Get Your Visa",
          text: "Complete your visa process while studying and be ready to go to the US by the end of the All American Undergraduate Program.",
        },
        {
          image: cardImg3,
          title: "Complete Overseas",
          text: "Progress to the overseas campus to complete the remainder of your degree and apply for post study work rights.",
        },
      ]);
      const [coursesGridData, setCoursesGridData] = useState([
        {
          image: bussinessImag,
          text: "Business & Management",
          gridR: "1/2",
          gridC: "1/2",
          gridRM: "1/2",
          gridCM: "1/4",
        },
        {
          image: commerseImag,
          text: "Commerce & Finance",
          gridR: "1/2",
          gridC: "2/3",
          gridRM: "2/3",
          gridCM: "1/4",
        },
        {
          image: marketingImage,
          text: "Marketing",
          gridR: "2/3",
          gridC: "1/2",
          gridRM: "4/5",
          gridCM: "1/4",
        },
        {
          image: filmStudiesImage,
          text: "Film Studies",
          gridR: "2/3",
          gridC: "3/4",
          gridRM: "6/7",
          gridCM: "1/4",
        },
        {
          image: EngineeringImage,
          text: "Engineering",
          gridR: "3/4",
          gridC: "1/2",
          gridRM: "7/8",
          gridCM: "1/4",
        },
        {
          image: sportsImage,
          text: "Sports Management",
          gridR: "2/3",
          gridC: "2/3",
          gridRM: "5/6",
          gridCM: "1/4",
        },
        {
          image: scienceImage,
          text: "Science",
          gridR: "3/4",
          gridC: "2/3",
          gridRM: "8/9",
          gridCM: "1/4",
        },
        {
          image: computerImage,
          text: "Computer Science & Information Technology",
          gridR: "1/2",
          gridC: "3/4",
          gridRM: "3/4",
          gridCM: "1/4",
        },
      ]);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    };

    return (
      <div>
        <div
          className="program-page"
          style={{ fontFamily: "'Poppins', sans-serif;" }}
        >
          <Layout>
            <Helmet>
              <title>{metatitle}</title>
              <meta name="description" content={metadescription} />
              {(() => {
                if (
                  post.acf.focus_keyphrase != "" &&
                  post.acf.focus_keyphrase != null
                ) {
                  return <meta name="keywords" content={focus_keyphrase} />;
                }
              })()}
              <link rel="canonical" href={canonical_url} />
              {(() => {
                if (
                  post.acf.dynamic_schema != "" &&
                  post.acf.dynamic_schema != null
                ) {
                  return (
                    <script type="application/ld+json">
                      {JSON.stringify(JSON.parse(post.acf.dynamic_schema))}
                    </script>
                  );
                }
              })()}
            </Helmet>

            <div class="newStyle font-poppins">
              <div className="container">
                <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
                  <li class="breadcrumb-item">
                    <a href="/">Home </a>
                  </li>
                  <li class="breadcrumb-item active"> {post.title}</li>
                </ol>
              </div>
              <section
                class="wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-100px-tb position-relative bg-cover480br extra-big-section"
                style={{ backgroundImage: "url(" + headerBackground + ")" }}
              >
                <div class="opacity-medium bg-medium-slate-blue"></div>
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-xl-8 col-lg-8 col-md-8 position-relative page-title-large ">
                      <h1 class="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-10px-bottom">
                        {post.title}
                      </h1>
                      <div
                        class="alt-font text-white no-margin-bottom"
                        dangerouslySetInnerHTML={{
                          __html:
                            post.acf.program_detail_section
                              .program_detail_content,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section class="half-section with-bullet">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-10 md-margin-one-bottom ">
                      <div class="col-12 p-0 wow animate__slideInLeft">
                        <div class="w-90 lg-w-80 sm-w-100 text-justify ">
                          <div class="no-margin-bottom program-content">
                            {ReactHtmlParser(post.content)}
                          </div>
                          <div class="no-margin-bottom program-content-second mobile">
                            {ReactHtmlParser(post.acf.remaining_description)}
                          </div>

                          <a
                            data-animation="animated zoomInUp"
                            class="btn btn-theme effect btn-md modal-popup center-button-110 mobile"
                            href={
                              post.acf.program_detail_section
                                .program_brochure_file.source_url
                            }
                          >
                            Download Brochure
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      class="animate__slideInRight animated bg-cover br-15 col-12 col-lg-6 margin15 mt-5 mt-lg-0  mx-0 p-0 position-relative wow popup-video mb25"
                      data-wow-delay="0.1s"
                      style={{
                        backgroundImage:
                          "url(" + post.acf.youtube_image.source_url + ")",
                      }}
                    >
                      <img
                        src={post.acf.youtube_image.source_url}
                        alt="YouTube"
                        data-no-retina=""
                        class="br-15 margin15 opacity-0 "
                      />
                      <div class="opacity-very-light bg-medium-slate-blue br-15 "></div>
                      <PopupVideo
                        time={Math.random()}
                        videoURL={post.acf.youtube_video}
                      />
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="no-margin-bottom program-content-second desktop">
                      {ReactHtmlParser(post.acf.remaining_description)}
                      <a
                        data-animation="animated zoomInUp"
                        class="btn btn-theme effect btn-md modal-popup center-button-110 desktop"
                        href={
                          post.acf.program_detail_section.program_brochure_file
                            .source_url
                        }
                      >
                        Download Brochure
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div class="newStyle font-poppins">
              <section
                id="universities"
                class="half-section bg-light-gray wow animate__fadeIn"
              >
                <div class="container">
                  <div class="row">
                    <div class="col-md-12  text-center margin-two-bottom">
                      <h2 class="margin-15px-bottom">
                        {
                          post.acf.featured_partner_universities_section
                            .featured_partner_universities_title
                        }
                      </h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12  position-relative">
                      <div class="swiper-container text-center swiper-university">
                        <UniLogoSlider
                          logoSlider={
                            post.acf.featured_partner_universities_section
                              .featured_partner_universities_select
                          }
                          pageType="Other"
                        />
                      </div>
                      <div class="swiper-pagination swiper-pagination-university swiper-pagination-new"></div>
                      <div class="swiper-button-next swiper-next-university rounded-circle light slider-navigation-style-07 box-shadow-double-large">
                        <img
                          src={ArrowRightSolidIcon}
                          alt="->"
                          className="left-icon newicon newicon-black left2pxtop0px"
                        />
                      </div>
                      <div class="swiper-button-prev swiper-prev-university rounded-circle light slider-navigation-style-07 box-shadow-double-large">
                        <img
                          src={ArrowLeftSolidIcon}
                          alt="<-"
                          className="left-icon newicon newicon-black left2pxtop0px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="half-section wow animate__fadeIn uk-program-process">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-md-12 text-center margin-five-bottom">
                      <h2>
                        {
                          post.acf.worldgrad_journey_section
                            .worldgrad_journey_title
                        }
                      </h2>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div
                      class="col-12 col-lg-4 col-sm-4 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn"
                      data-wow-delay="0.2s"
                    >
                      <div class="process-step-item margin-40px-bottom">
                        <div class="process-step-item-box">
                          <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15"></span>
                          <div class="process-step-icon">
                            <span class="process-step-number text-white font-weight-500 br-15">
                              <span class="process-step-number-bfr bg-transparent-sky-blue "></span>
                              <span class="process-step-year">
                                {
                                  post.acf.worldgrad_journey_section.journey_year_1_title.split(
                                    ":"
                                  )[0]
                                }
                              </span>{" "}
                              <span class="process-step-number-afr bg-fast-blue"></span>
                            </span>
                          </div>
                          <div class="process-content last-paragraph-no-margin">
                            <span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom">
                              {
                                post.acf.worldgrad_journey_section.journey_year_1_title.split(
                                  ":"
                                )[1]
                              }
                            </span>
                            <p
                              class="margin-10px-bottom"
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.acf.worldgrad_journey_section
                                    .program_provider_content,
                              }}
                            ></p>
                            <p
                              class="margin-10px-bottom"
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.acf.worldgrad_journey_section
                                    .year_1_study_mode,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                      <img
                        src={ArrowRightBlueIcon}
                        alt="->"
                        className="left-icon process-icon-next new-process-icon-next"
                      />
                    </div>

                    <div
                      class="col-12 col-lg-4 col-sm-4 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <div class="process-step-item process-step-item-p40 margin-40px-bottom">
                        <div class="process-step-item-box">
                          <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15"></span>
                          <div class="process-step-icon">
                            <span class="process-step-number text-white font-weight-500 br-15">
                              <span class="process-step-number-bfr bg-transparent-sky-blue "></span>
                              <span class="process-step-year">
                                {
                                  post.acf.worldgrad_journey_section.journey_year_2_title.split(
                                    ":"
                                  )[0]
                                }
                              </span>{" "}
                              <span class="process-step-number-afr bg-fast-blue"></span>
                            </span>
                          </div>
                          <div class="process-content last-paragraph-no-margin">
                            <span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom">
                              {
                                post.acf.worldgrad_journey_section.journey_year_2_title.split(
                                  ":"
                                )[1]
                              }
                            </span>
                            <p
                              class="margin-10px-bottom"
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.acf.worldgrad_journey_section
                                    .course_provider_content,
                              }}
                            ></p>
                            <p
                              class="margin-10px-bottom"
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.acf.worldgrad_journey_section
                                    .year_2_study_mode,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                      <img
                        src={ArrowRightBlueIcon}
                        alt="->"
                        className="left-icon process-icon-next2 new-process-icon-next2"
                      />
                    </div>
                    <div
                      class="col-12 col-lg-4 col-sm-4 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <div class="process-step-item">
                        <div class="process-step-item-box">
                          <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15"></span>
                          <div class="process-step-icon">
                            <span class="process-step-number text-white font-weight-500 br-15">
                              <span class="process-step-number-bfr bg-transparent-sky-blue "></span>
                              <span class="process-step-year">
                                {
                                  post.acf.worldgrad_journey_section.journey_year_3_title.split(
                                    ":"
                                  )[0]
                                }
                              </span>{" "}
                              <span class="process-step-number-afr bg-fast-blue"></span>
                            </span>
                          </div>
                          <div class="process-content last-paragraph-no-margin">
                            <span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom">
                              {
                                post.acf.worldgrad_journey_section.journey_year_3_title.split(
                                  ":"
                                )[1]
                              }
                            </span>
                            <p
                              class="margin-10px-bottom"
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.acf.worldgrad_journey_section
                                    .course_provider_3_content,
                              }}
                            ></p>
                            <p
                              class="margin-10px-bottom"
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.acf.worldgrad_journey_section
                                    .year_3_study_mode,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="half-section bg-light-gray wow animate__fadeIn">
                <div class="container">
                  <div class="row">
                    <div class="col-md-10 offset-md-1 text-center margin-two-bottom">
                      <h2>
                        {post.acf.right_choice_section.right_choice_title}
                      </h2>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    {post.acf.right_choice_section.right_choice_item.map(
                      (item, index) => {
                        return (
                          <div className="col-12 col-lg-6 col-md-9 margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn">
                            <div className="feature-box br-15 h-100 feature-box-left-icon bg-white box-shadow-small box-shadow-extra-large-hover overflow-hidden padding-2-rem-all">
                              <div className="feature-box-content last-paragraph-no-margin text-justify">
                                <div
                                  class="text-center"
                                  style={{ paddingBottom: "15px" }}
                                >
                                  <img
                                    src={item.right_choice_item_icon.source_url}
                                    alt="✓"
                                  />
                                </div>

                                <p className="alt-font font-weight-500 margin-10px-bottom d-block text-center text-extra-dark-gray font-size-18">
                                  {item.right_choice_item_title}
                                </p>
                                <p
                                  classname="american-dream-card-text"
                                  dangerouslySetInnerHTML={{
                                    __html: item.right_choice_item_content,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <section class="half-section bg-light-gray wow animate__fadeIn pt-0">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 text-center margin-two-bottom">
                      <h2>{post.acf.popular_degrees_title}</h2>
                    </div>
                  </div>
                  <PopularDegreeOutcome
                    degrees={post.acf.popular_degrees_select}
                  />

                  <div class="text-center padding-25px-top">
                    <a
                      data-animation="animated zoomInUp"
                      class="btn btn-theme effect btn-md"
                      href="/program-listing/?key=Global Online Accelerator Program"
                    >
                      Explore More Degrees
                    </a>
                  </div>
                </div>
              </section>

              <section class="half-section">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-10 md-margin-50px-bottom">
                      <div class="col-12 p-0 margin-3-rem-bottom  wow animate__slideInLeft">
                        <h4 class="alt-font text-extra-dark-gray d-inline-block w-100 lg-w-100 sm-w-100 text-center text-center">
                          {
                            post.acf.admissions_requirements_section
                              .admissions_requirements_title
                          }
                        </h4>

                        <div class="col-12 p-0 process-box">
                          <div
                            class="col-12 p-0 process-step-style-02 wow animate__fadeIn"
                            data-wow-delay="0.2s"
                          >
                            <div class="process-step-item">
                              <div class="process-step-icon-wrap">
                                <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight-500">
                                  1
                                </div>
                                <span class="process-step-item-box-bfr bg-extra-medium-gray "></span>
                              </div>
                              <div class="process-content last-paragraph-no-margin">
                                <span class="alt-font d-block font-weight-500 text-extra-dark-gray margin-5px-bottom ">
                                  {
                                    post.acf.admissions_requirements_section
                                      .admissions_requirements_item[0]
                                      .admissions_requirements_item_title
                                  }
                                </span>
                                <p class="w-90 xs-w-100 text-left">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.acf.admissions_requirements_section
                                          .admissions_requirements_item[0]
                                          .admissions_requirements_item_content[0]
                                          .admissions_requirements_item_details,
                                    }}
                                  ></span>
                                  <br />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.acf.admissions_requirements_section
                                          .admissions_requirements_item[0]
                                          .admissions_requirements_item_content[1]
                                          .admissions_requirements_item_details,
                                    }}
                                  ></span>
                                  {/*<br />
											{post.acf.admissions_requirements_section.admissions_requirements_item[0].admissions_requirements_item_content[2].admissions_requirements_item_details} */}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 p-0 process-step-style-02 wow animate__fadeIn"
                            data-wow-delay="0.4s"
                          >
                            <div class="process-step-item">
                              <div class="process-step-icon-wrap">
                                <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight-500">
                                  2
                                </div>
                                <span class="process-step-item-box-bfr bg-extra-medium-gray "></span>
                              </div>
                              <div class="process-content last-paragraph-no-margin">
                                <span class="alt-font d-block font-weight-500 text-extra-dark-gray margin-5px-bottom ">
                                  {
                                    post.acf.admissions_requirements_section
                                      .admissions_requirements_item[1]
                                      .admissions_requirements_item_title
                                  }
                                </span>
                                <p class="w-90 xs-w-100 text-left">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.acf.admissions_requirements_section
                                          .admissions_requirements_item[1]
                                          .admissions_requirements_item_content[0]
                                          .admissions_requirements_item_details,
                                    }}
                                  ></span>
                                  <br />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.acf.admissions_requirements_section
                                          .admissions_requirements_item[1]
                                          .admissions_requirements_item_content[1]
                                          .admissions_requirements_item_details,
                                    }}
                                  ></span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 p-0 process-step-style-02 wow animate__fadeIn"
                            data-wow-delay="0.6s"
                          >
                            <div class="process-step-item">
                              <div class="process-step-icon-wrap">
                                <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight-500">
                                  3
                                </div>
                              </div>
                              <div class="process-content last-paragraph-no-margin">
                                <span class="alt-font d-block font-weight-500 text-extra-dark-gray margin-5px-bottom ">
                                  {
                                    post.acf.admissions_requirements_section
                                      .admissions_requirements_item[2]
                                      .admissions_requirements_item_title
                                  }
                                </span>
                                <p class="w-90 xs-w-100 text-left">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.acf.admissions_requirements_section
                                          .admissions_requirements_item[2]
                                          .admissions_requirements_item_content[0]
                                          .admissions_requirements_item_details,
                                    }}
                                  ></span>
                                  <br />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.acf.admissions_requirements_section
                                          .admissions_requirements_item[2]
                                          .admissions_requirements_item_content[1]
                                          .admissions_requirements_item_details,
                                    }}
                                  ></span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <a
                            data-animation="animated zoomInUp"
                            class="btn btn-theme effect btn-md center-button-110 modal-popup"
                            id="speak-to-an-expert"
                            href="#get-in-touch"
                          >
                            Speak to an Expert
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-lg-6 position-relative md-margin-50px-bottom wow animate__slideInRight text-center  "
                      data-wow-delay="0.1s"
                    >
                      <h4 class="alt-font text-extra-dark-gray d-inline-block w-100 lg-w-100 sm-w-100 text-center text-center">
                        {
                          post.acf.admissions_requirements_section
                            .sample_transcript_title
                        }
                      </h4>
                      <img
                        src={
                          post.acf.admissions_requirements_section
                            .sample_transcript_image.source_url
                        }
                        alt={
                          post.acf.admissions_requirements_section
                            .sample_transcript_title
                        }
                        data-no-retina=""
                        class="transcript"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section class="half-section bg-dark-gray wow animate__fadeIn">
                <div class="container">
                  <div class="row">
                    <div class="col-md-8 offset-md-2 text-center margin-two-bottom">
                      <h2>{post.acf.what_will_you_study_new_title}</h2>
                      <p>{post.acf.what_will_you_study_new_subtitle}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div
                      class="col-12 col-lg-12 col-md-10"
                      id="program-accordion-sec"
                    >
                      {post.acf.what_will_you_study_new_item.map(
                        (item, index) => {
                          return (
                            <Accordion
                              className="customize-accord"
                              defaultExpanded={true}
                            >
                              <AccordionSummary
                                expandIcon={
                                  <svg
                                    class="MuiSvgIcon-root"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                  >
                                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                  </svg>
                                }
                                className="customize-accord-common customize-accord-title"
                              >
                                <h4 class="text-center width-100-per">
                                  {item.what_will_you_study_item_new_title}
                                </h4>
                              </AccordionSummary>
                              <AccordionDetails className="customize-accord-body p-0">
                                {item.what_will_you_study_new_subitem.map(
                                  (e, i) => (
                                    <Accordion
                                      defaultExpanded={false}
                                      className="customize-accord panel bg-white box-shadow-small border-radius-5px margin-30px-bottom"
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root"
                                            focusable="false"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                          >
                                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                          </svg>
                                        }
                                        className="customize-accord-common customize-accord-title-title"
                                      >
                                        {
                                          e.what_will_you_study_new_subitem_title
                                        }
                                      </AccordionSummary>
                                      <AccordionDetails
                                        className="customize-accord-body-body"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            e.what_will_you_study_new_subitem_content,
                                        }}
                                      />
                                    </Accordion>
                                  )
                                )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </section>

              {post.acf.faqs_section?.faqs_item != null ? (
                <section class="half-section bg-white  wow animate__fadeIn grayfaq">
                  <div class="container">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="text-center">
                          {" "}
                          {post.acf.faqs_section.faqs_title}{" "}
                        </h2>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <GenericFaqSection FaqsItem={post.acf.faqs_section} />
                    </div>
                  </div>
                  <p class="text-center"></p>
                </section>
              ) : (
                <p class="text-center"></p>
              )}
            </div>
          </Layout>
        </div>
      </div>
    );
  }
}

export default AmericanDreamUG;

export const WorldGradUniversities = graphql`
  {
    allWordpressWpUniversities(
      filter: { acf: { country: { eq: "United States of America" } } }
    ) {
      nodes {
        id
        slug
        title
        acf {
          banner_image {
            source_url
          }
        }
      }
    }
    allWordpressPage(filter: { wordpress_id: { in: 4933 } }) {
      edges {
        node {
          wordpress_id
          title
          featured_media {
            source_url
          }
          content
          acf {
            what_will_you_study_new_title
            what_will_you_study_new_subtitle
            what_will_you_study_new_item {
              what_will_you_study_item_new_title
              what_will_you_study_new_subitem {
                what_will_you_study_new_subitem_title
                what_will_you_study_new_subitem_content
              }
            }
            meta_title
            meta_description
            focus_keyphrase
            canonical_url
            dynamic_schema
            popular_degrees_select {
              post_title
              post_name
              wordpress_id
              featured_media {
                source_url
              }
              program_duration
              university_ranking
              university_logo {
                source_url
              }
              program_url
              degree_type
              country
              select_university_post_name
            }
            right_choice_section {
              right_choice_title
              right_choice_item {
                right_choice_item_content
                right_choice_item_title
                right_choice_item_icon {
                  source_url
                }
              }
            }
            remaining_description
            program_detail_section {
              program_detail_content
              program_detail_title
              program_brochure_file {
                source_url
              }
            }
            worldgrad_journey_section {
              course_provider_content
              program_provider_content
              course_provider_3_content
              worldgrad_journey_title
              journey_year_1_title
              journey_year_2_title
              journey_year_3_title

              credits_year_1
              credits_year_2
              credits_year_3
              year_1_study_mode
              year_2_study_mode
              year_3_study_mode
            }
            featured_partner_universities_section {
              featured_partner_universities_title
              featured_partner_universities_select {
                wordpress_id
                post_name
                post_title
                university_link
                university_logo {
                  id
                  source_url
                }
              }
            }

            admissions_requirements_section {
              speak_to_an_expert_button {
                target
                title
                url
              }
              sample_transcript_title
              sample_transcript_image {
                source_url
              }
              admissions_requirements_title
              admissions_requirements_item {
                admissions_requirements_item_title
                admissions_requirements_item_content {
                  admissions_requirements_item_details
                }
              }
            }
            popular_degrees_title
            youtube_video
            youtube_image {
              source_url
            }
            faqs_section {
              faqs_title
              faqs_item {
                faqs_item_content
                faqs_item_title
              }
            }
          }
        }
      }
    }
  }
`;
